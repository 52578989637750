import { combineReducers } from "redux";
import Auth from "./Auth";
import RoleManagement from "./RoleManagement";
import Theme from "./Theme";
import UserManagement from "./UserManagement";
import StatusDoor from "./StatusDoor";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  userManagement: UserManagement,
  roleManagement: RoleManagement,
  statusDoor: StatusDoor,
});

export default reducers;
