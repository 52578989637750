const dev = {
  AUTHEN_URL: process.env.REACT_APP_AUTHEN_URL,
  USER_MANAGEMENT_URL: process.env.REACT_APP_USER_MANAGEMENT_URL,
  CAR_COUNTING_URL: process.env.REACT_APP_CAR_COUNTING_URL,
};

const prod = {
  AUTHEN_URL: process.env.REACT_APP_AUTHEN_URL,
  USER_MANAGEMENT_URL: process.env.REACT_APP_USER_MANAGEMENT_URL,
  CAR_COUNTING_URL: process.env.REACT_APP_CAR_COUNTING_URL,
};

const test = {
  AUTHEN_URL: process.env.REACT_APP_AUTHEN_URL,
  USER_MANAGEMENT_URL: process.env.REACT_APP_USER_MANAGEMENT_URL,
  CAR_COUNTING_URL: process.env.REACT_APP_CAR_COUNTING_URL,
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      return {
        AUTHEN_URL: "xxxx",
        USER_MANAGEMENT_URL: "xxxx",
        CAR_COUNTING_URL: "xxxx",
      };
  }
};

export const env = getEnv();
