import fetch from "auth/FetchInterceptor";
import { USER_MANAGEMENT_URL } from "configs/AppConfig";

const UserManagementService = {};

UserManagementService.UM01_CreateUser = async (data) =>
  await fetch({
    url: `${USER_MANAGEMENT_URL}/users`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

UserManagementService.UM02_GetListUser = async () =>
    await fetch({
      url: `${USER_MANAGEMENT_URL}/users`,
      method: "get",
    })
      .then((response) => response)
      .catch((error) => error);

UserManagementService.UM03_GetUserDetail = async (id) =>
  await fetch({
    url: `${USER_MANAGEMENT_URL}/users/${id}`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);


UserManagementService.UM04_UpdateUserDetail = async (data) =>
  await fetch({
    url: `${USER_MANAGEMENT_URL}/users/${data._id}`,
    method: "put",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

UserManagementService.UM05_RemoveUser = async (id) =>
  await fetch({
    url: `${USER_MANAGEMENT_URL}/users/${id}`,
    method: "delete",
  })
    .then((response) => response)
    .catch((error) => error);

UserManagementService.CP01_ChangePassword = async (data) =>
  await fetch({
    url: `${USER_MANAGEMENT_URL}/users/change-password`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

UserManagementService.VP01_VerifyPassword = async (data) =>
  await fetch({
    url: `${USER_MANAGEMENT_URL}/users/verify-password`,
    method: "post",
    data,
  })
    .then((response) => response)
    .catch((error) => error);

export default UserManagementService;
