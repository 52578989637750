export const LIST_USER_MANAGEMENT = "LIST_USER_MANAGEMENT";
export const LIST_USER_MANAGEMENT_SUCCESS = "LIST_USER_MANAGEMENT_SUCCESS";
export const LIST_USER_MANAGEMENT_FAILED = "LIST_USER_MANAGEMENT_FAILED";
export const CREATE_USER_MANAGEMENT = "CREATE_USER_MANAGEMENT";
export const CREATE_USER_MANAGEMENT_SUCCESS = "CREATE_USER_MANAGEMENT_SUCCESS";
export const CREATE_USER_MANAGEMENT_FAILED = "CREATE_USER_MANAGEMENT_FAILED";
export const GET_USER_MANAGEMENT = "GET_USER_MANAGEMENT";
export const GET_USER_MANAGEMENT_SUCCESS = "GET_USER_MANAGEMENT_SUCCESS";
export const GET_USER_MANAGEMENT_FAILED = "GET_USER_MANAGEMENT_FAILED";
export const UPDATE_USER_MANAGEMENT = "UPDATE_USER_MANAGEMENT";
export const UPDATE_USER_MANAGEMENT_SUCCESS = "UPDATE_USER_MANAGEMENT_SUCCESS";
export const UPDATE_USER_MANAGEMENT_FAILED = "UPDATE_USER_MANAGEMENT_FAILED";
export const UPDATE_USER_MANAGEMENT_PASSWORD = "UPDATE_USER_MANAGEMENT_PASSWORD";
export const UPDATE_USER_MANAGEMENT_PASSWORD_SUCCESS = "UPDATE_USER_MANAGEMENT_PASSWORD_SUCCESS";
export const UPDATE_USER_MANAGEMENT_PASSWORD_FAILED = "UPDATE_USER_MANAGEMENT_PASSWORD_FAILED";
export const DELETE_USER_MANAGEMENT = "DELETE_USER_MANAGEMENT";
export const DELETE_USER_MANAGEMENT_SUCCESS = "DELETE_USER_MANAGEMENT_SUCCESS";
export const DELETE_USER_MANAGEMENT_FAILED = "DELETE_USER_MANAGEMENT_FAILED";