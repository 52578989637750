import _ from "lodash";
import {
  LIST_ROLE_MANAGEMENT_SUCCESS,
  DELETE_ROLE_MANAGEMENT_SUCCESS,
} from "redux/constants/RoleManagement";

const initialState = {};

const RoleManagement = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ROLE_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        user: undefined,
        listRoleManagement: action.payload,
      };
    }

    case DELETE_ROLE_MANAGEMENT_SUCCESS: {
      const { data } = state.listRoleManagement;
      return {
        ...state,
        listRoleManagement: {
          data: _.filter(data, ({ _id }) => _id !== action.payload),
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default RoleManagement;
