import fetch from "auth/FetchInterceptor";
import { USER_MANAGEMENT_URL } from "configs/AppConfig";

const RoleManagementService = {};

RoleManagementService.RM02_List = async () =>
  await fetch({
    url: `${USER_MANAGEMENT_URL}/roles`,
    method: "get",
  })
    .then((response) => response)
    .catch((error) => error);

RoleManagementService.RM03_Get = (id) => {
  const url = USER_MANAGEMENT_URL + "/roles/" + id;
  return fetch({
    url: url,
    method: "GET",
    headers: {
      "public-request": "true",
    },
    data: {},
  });
};

RoleManagementService.RM04_UpdateRole = (id, data) => {
  const url = USER_MANAGEMENT_URL + "/roles/" + id;

  return fetch({
    url: url,
    method: "PUT",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

RoleManagementService.RM01_Create = (data) => {
  const url = USER_MANAGEMENT_URL + "/roles";
  return fetch({
    url: url,
    method: "POST",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

RoleManagementService.RM05_DeleteRole = async (id) =>
  await fetch({
    url: `${USER_MANAGEMENT_URL}/roles/${id}`,
    method: "DELETE",
  })
    .then((response) => response)
    .catch((error) => error);

export default RoleManagementService;
