import {
  LIST_ROLE_MANAGEMENT,
  DELETE_ROLE_MANAGEMENT,
} from "redux/constants/RoleManagement";
import {
  listRoleManagementSuccess,
  deleteRoleManagementSuccess,
} from "redux/actions/RoleManagement";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import RoleManagementService from "services/RoleManagementService";

function* listRoleManagement() {
  yield takeEvery(LIST_ROLE_MANAGEMENT, function* ({ payload }) {
    try {
      const response = yield call(RoleManagementService.RM02_List, payload);
      if (response.code === 200) {
        yield put(listRoleManagementSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* deleteRoleManagement() {
  yield takeEvery(
    DELETE_ROLE_MANAGEMENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          RoleManagementService.RM05_DeleteRole,
          payload
        );
        if (response.code === 200) {
          onFinish();
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

export default function* rootSaga() {
  yield all([fork(listRoleManagement), fork(deleteRoleManagement)]);
}
