import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from '../constants/ThemeConstant';
import {env} from './EnvironmentConfig'

export const APP_NAME = 'Unifence';

export const AUTHEN_API = env.AUTHEN_URL;
export const USER_MANAGEMENT_URL = env.USER_MANAGEMENT_URL;
export const CAR_COUNTING_URL = env.CAR_COUNTING_URL;

export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/app';

export const THEME_CONFIG = {
	navCollapsed: true,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'th',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
};
