import Icon, {
  DashboardOutlined,
  DotChartOutlined,
  FileDoneOutlined,
  TeamOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { UserOctagon } from "assets/svg/icon";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const img1 = document.createElement("img");
img1.src = "../img/CameraBlack.png";

const dashBoardNavTree = [
  {
    key: "DASHBOARD",
    path: `${APP_PREFIX_PATH}/home`,
    title: "หน้าหลัก",
    icon: <DashboardOutlined />,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "ACCESS_CONTROL",
    path: `${APP_PREFIX_PATH}/access-control`,
    title: "Access Control",
    icon: <Icon component={UserOctagon} />,
    breadcrumb: false,
    submenu: [],
  },
  // {
  //   key: "BUILDING",
  //   path: `${APP_PREFIX_PATH}/building`,
  //   title: "unknow",
  //   icon: DotChartOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "REPORT",
  //   path: `${APP_PREFIX_PATH}/report`,
  //   title: "unknow",
  //   icon: FileDoneOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "USER",
  //   path: `${APP_PREFIX_PATH}/user`,
  //   title: "unknow",
  //   icon: TeamOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "ROLE",
  //   path: `${APP_PREFIX_PATH}/role`,
  //   title: "unknow",
  //   icon: IdcardOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
