import {
  LIST_ROLE_MANAGEMENT_SUCCESS,
  DELETE_ROLE_MANAGEMENT,
} from "redux/constants/RoleManagement";

export const listRoleManagementSuccess = (payload) => {
  return {
    type: LIST_ROLE_MANAGEMENT_SUCCESS,
    payload,
  };
};

export const deleteRoleManagementSuccess = (payload) => {
  return {
    type: DELETE_ROLE_MANAGEMENT,
    payload,
  };
};
