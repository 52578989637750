import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  LIST_USER_MANAGEMENT,
  CREATE_USER_MANAGEMENT,
  GET_USER_MANAGEMENT,
  UPDATE_USER_MANAGEMENT,
  UPDATE_USER_MANAGEMENT_PASSWORD,
  DELETE_USER_MANAGEMENT,
} from "../constants/UserManagement";
import {
  listUserManagementSuccess,
  createUserManagementSuccess,
  getUserManagementSuccess,
  updateUserManagementSuccess,
  updateUserManagementPasswordSuccess,
  deleteUserManagementSuccess,
} from "../actions/UserManagement";

import UserManagementService from "services/UserManagementService";

function* listUserManagement() {
  yield takeEvery(LIST_USER_MANAGEMENT, function* ({ payload }) {
    try {
      const response = yield call(
        UserManagementService.UM02_GetListUser,
        payload
      );
      if (response.code === 200) {
        yield put(listUserManagementSuccess(response));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* createUserManagement() {
  yield takeEvery(
    CREATE_USER_MANAGEMENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          UserManagementService.UM01_CreateUser,
          payload
        );
        if (response.code === 201) {
          yield put(createUserManagementSuccess(response.data));
          onFinish(response);
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* getUserManagement() {
  yield takeEvery(GET_USER_MANAGEMENT, function* ({ payload }) {
    try {
      const response = yield call(
        UserManagementService.UM03_GetUserDetail,
        payload
      );
      if (response.code === 200) {
        yield put(getUserManagementSuccess(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* updateUserManagement() {
  yield takeEvery(
    UPDATE_USER_MANAGEMENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          UserManagementService.UM04_UpdateUserDetail,
          payload
        );
        if (response.code === 200) {
          yield put(updateUserManagementSuccess(response.data));
          onFinish(response.data);
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* updateUserManagementPassword() {
  yield takeEvery(
    UPDATE_USER_MANAGEMENT_PASSWORD,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          UserManagementService.CP01_ChangePassword,
          payload
        );
        if (response.code === 200) {
          yield put(updateUserManagementPasswordSuccess(response));
          onFinish(response);
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

function* deleteUserManagement() {
  yield takeEvery(
    DELETE_USER_MANAGEMENT,
    function* ({ payload, onFinish, onFailed }) {
      try {
        const response = yield call(
          UserManagementService.UM05_RemoveUser,
          payload
        );
        if (response.code === 200) {
          yield put(deleteUserManagementSuccess(payload));
          onFinish();
        } else {
          onFailed(response);
        }
      } catch (error) {
        onFailed(error);
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(listUserManagement),
    fork(createUserManagement),
    fork(getUserManagement),
    fork(updateUserManagement),
    fork(updateUserManagementPassword),
    fork(deleteUserManagement),
  ]);
}
