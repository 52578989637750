import _ from "lodash";
import {
  LIST_USER_MANAGEMENT_SUCCESS,
  CREATE_USER_MANAGEMENT_SUCCESS,
  GET_USER_MANAGEMENT,
  GET_USER_MANAGEMENT_SUCCESS,
  UPDATE_USER_MANAGEMENT_SUCCESS,
  UPDATE_USER_MANAGEMENT_PASSWORD_SUCCESS,
  DELETE_USER_MANAGEMENT_SUCCESS,
} from "../constants/UserManagement";

const initialState = {}

const UserManagement = (state = initialState, action) => {
  switch (action.type) {
    case LIST_USER_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        user: undefined,
        listUser: action.payload,
      };
    }
    case CREATE_USER_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        listUser: {
          data: [action.payload, ...state.listUser.data],
        },
      };
    }
    case GET_USER_MANAGEMENT: {
      return {
        ...state,
        user: undefined,
      };
    }
    case GET_USER_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case UPDATE_USER_MANAGEMENT_SUCCESS: {
      const editData = state.listUser.data;
      const dataIndex = _.findIndex(
        editData,
        ({ id }) => id === action.payload.id
      );
      editData[dataIndex] = action.payload;
      return {
        ...state,
        listUser: {
          ...state.listUser,
          data: [...editData],
        },
      };
    }
    case UPDATE_USER_MANAGEMENT_PASSWORD_SUCCESS: {
      const editData = state.listUser.data;
      const dataIndex = _.findIndex(
        editData,
        ({ _id }) => _id === action.payload._id
      );
      editData[dataIndex] = action.payload;
      return {
        ...state,
        listUser: {
          ...state.listUser,
          data: [...editData],
        },
      };
    }
    case DELETE_USER_MANAGEMENT_SUCCESS: {
      const { data } = state.listUser;
      return {
        ...state,
        listUser: {
          data: _.filter(
            data,
            ({ _id }) => _id !== action.payload
          ),
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default UserManagement;
