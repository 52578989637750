import { LIST_STATUSDOOR_SUCCESS } from "redux/constants/StatusDoor";

const initialState = {};

const StatusDoor = (state = initialState, action) => {
  switch (action.type) {
    case LIST_STATUSDOOR_SUCCESS: {
      return {
        ...state,
        user: undefined,
        listStatusDoor: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default StatusDoor;
