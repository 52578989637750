import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import store from "redux/store";

export const AppViews = () => {
  const token = store.getState().auth.token;
  // if (token === null || token === "")
  //   return <Redirect from={`${APP_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}`} />;

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/access-control/:id`}
          component={lazy(() => import(`./access-control/detail`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/access-control`}
          component={lazy(() => import(`./access-control`))}
        />

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
