import React from "react";

export const ReplySVG = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
    <path
      d="M537.016,909.264c-7.034,0-14.006-2.372-19.671-6.97L18.508,496.986C11.232,491.055,7,482.161,7,472.781
			c0-9.374,4.232-18.267,11.508-24.204L517.345,43.272c9.318-7.551,22.258-9.104,33.064-3.959
			c10.871,5.175,17.785,16.135,17.785,28.162v219.277c243.388,16.107,436.483,219.246,436.483,466.625v62.353
			c0,12.18-7.097,23.235-18.147,28.314c-11.054,5.119-24.054,3.292-33.311-4.626l-52.55-45.027
			c-93.318-79.986-210.359-126.841-332.476-133.66v217.36c0,12.022-6.914,22.986-17.785,28.158
			C546.146,908.262,541.58,909.268,537.016,909.264L537.016,909.264z M125.715,472.781L506.65,782.309V614.776
			c0-15.697,12.702-28.401,28.399-28.401c134.946,0,265.707,48.367,368.18,136.193l0.972,0.834
			c-2.664-201.286-167.231-364.213-369.148-364.213c-15.699,0-28.4-12.704-28.4-28.399V163.258
			C506.65,163.258,125.715,472.781,125.715,472.781L125.715,472.781z"
    />
  </svg>
);

export const GoogleSVG = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
    <g>
      <path
        fill="#59C36A"
        d="M827.301,875.968c-84.521,71.388-194.169,116.693-313.528,116.693c-177.609,0-333.52-97.848-418.041-240.62
				l31.646-145.858l140.255-26.039c32.553,105.078,130.779,182.178,246.141,182.178c55.964,0,107.937-17.703,150.767-49.112
				l134.777,20.558L827.301,875.968z"
      />
      <path
        fill="#00A66C"
        d="M827.301,875.968l-27.984-142.201l-134.777-20.558c-42.83,31.409-94.803,49.112-150.767,49.112v230.34
				C633.132,992.661,742.779,947.355,827.301,875.968z"
      />
      <g id="Connected_Home_1_">
        <g>
          <g>
            <g>
              <path
                fill="#FFDA2D"
                d="M256.781,505.331c0,26.267,3.998,51.396,10.851,74.813l-171.9,171.897
								c-42.83-71.957-69.29-156.48-69.29-246.71c0-90.233,26.46-174.754,69.29-246.711l137.965,23.743l33.936,148.154
								C260.779,453.932,256.781,479.06,256.781,505.331L256.781,505.331z"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        fill="#4086F4"
        d="M1001.103,505.331c0,148.48-68.719,281.547-173.802,370.637L664.539,713.209
				c33.121-23.988,61.107-55.971,79.384-93.66h-230.15c-15.993,0-28.556-12.567-28.556-28.554V419.666
				c0-15.99,12.563-28.554,28.556-28.554h450.78c13.707,0,25.698,9.708,27.983,23.412
				C998.247,444.225,1001.103,475.063,1001.103,505.331L1001.103,505.331z"
      />
      <path
        fill="#4175DF"
        d="M743.924,619.549c-18.275,37.689-46.264,69.672-79.382,93.66l162.759,162.759
				c105.083-89.09,173.802-222.153,173.802-370.637c0-30.269-2.855-61.106-8.567-90.807c-2.284-13.704-14.278-23.412-27.984-23.412
				H513.772v228.437H743.924z"
      />
      <path
        fill="#FF641A"
        d="M835.297,154.107c0.571,7.996-2.855,15.422-7.996,21.131L705.086,296.881
				c-9.704,10.278-25.694,11.421-37.118,2.855c-45.119-33.693-98.231-51.396-154.195-51.396
				c-115.361,0-213.588,77.095-246.141,182.178L95.731,258.62C180.253,115.848,336.163,18,513.772,18
				c113.647,0,224.439,41.88,311.244,114.978C831.298,138.119,834.723,146.112,835.297,154.107L835.297,154.107z"
      />
      <path
        fill="#F03800"
        d="M667.966,299.736c11.422,8.567,27.411,7.423,37.119-2.855l122.214-121.643
				c5.143-5.709,8.569-13.133,7.996-21.131c-0.575-7.997-3.999-15.988-10.279-21.13C738.212,59.88,627.42,18,513.772,18v230.34
				C569.736,248.34,622.849,266.043,667.966,299.736z"
      />
    </g>
  </svg>
);

export const FacebookSVG = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
    <g>
      <path
        fill="#4A7AFF"
        d="M1015.363,506.525c0,279.749-226.775,506.524-506.525,506.524c-279.749,0-506.524-226.775-506.524-506.524
	C2.313,226.775,229.089,0,508.838,0C788.588,0,1015.363,226.775,1015.363,506.525z"
      />
      <path
        fill="#FFFFFF"
        d="M663.688,149.015v114.697c0,0-131.686-19.113-131.686,65.843v84.957h118.941L636.08,544.072H532.002v337.709
	H400.316V544.072l-112.572-2.126V414.512H398.19V316.81c0,0-7.288-145.343,135.938-172.038
	C593.602,133.68,663.688,149.015,663.688,149.015z"
      />
      <path
        fill="#DCE1EB"
        d="M663.688,263.712V149.015c0,0-70.086-15.335-129.56-4.243c-9.291,1.73-17.932,3.973-25.993,6.623v730.387
	h23.867V544.072h104.07l14.871-129.561H532.002c0,0,0,0,0-84.957C532.002,244.599,663.688,263.712,663.688,263.712z"
      />
    </g>
  </svg>
);

export const TwitterSVG = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
    <path
      fill="#03A9F4"
      d="M1006.701,163.276c-6.71-7.344-17.513-9.249-26.334-4.649c-9.059,4.107-18.431,7.508-28.042,10.136
      c14.983-19.171,26.285-40.938,33.356-64.208c3.113-11.713-3.859-23.729-15.573-26.834c-6.251-1.668-12.92-0.484-18.218,3.219
      c-33.365,18.063-68.824,31.936-105.59,41.3c-75.666-71.428-190.939-80.332-276.685-21.372
      c-66.047,44.667-102.419,121.845-94.796,201.207c-143.168-12.197-274.902-83.141-363.884-195.983
      c-4.574-5.527-11.491-8.575-18.653-8.206c-7.262,0.436-13.833,4.443-17.545,10.703c-30.202,49.365-38.951,108.916-24.231,164.878
      c7.548,28.757,20.485,55.813,38.136,79.74c-7.942-3.894-15.434-8.682-22.292-14.268c-9.413-7.632-23.229-6.193-30.86,3.22
      c-3.17,3.909-4.904,8.788-4.912,13.815c1.314,81.186,45.891,155.489,116.917,194.842c-9.586-1.175-19.04-3.204-28.264-6.054
      c-11.607-3.491-23.846,3.088-27.338,14.687c-1.519,5.06-1.166,10.505,1.004,15.326c30.826,69.055,92.447,119.488,166.24,136.039
      c-70.303,39.665-151.25,56.33-231.5,47.657c-10.399-1.323-20.255,5.002-23.385,15.015c-3.228,9.931,0.993,20.765,10.095,25.891
      c98.254,58.384,210.029,90.139,324.3,92.16c109.632-0.403,216.707-33.152,307.808-94.132
      C820.37,681.983,928.769,474.763,913.672,289.359c37.184-27.533,69.439-61.153,95.403-99.446
      C1014.406,181.519,1013.437,170.586,1006.701,163.276z"
    />
  </svg>
);

export const ImageSvg = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
    <g>
      <g>
        <path
          fill="#E5E1E5"
          d="M163.033,1011.756L2.746,191.353c-4.863-24.879,11.365-48.991,36.245-53.852l124.042-24.234l194.642-42.82
          l279.663,941.308H163.033V1011.756z"
        />
      </g>
      <path
        fill="#99E6FC"
        d="M163.033,680.979L68.355,196.393l94.678-18.501l143.802-23.615l62.994,584.599L163.033,680.979z"
      />
      <g id="XMLID_159_">
        <g>
          <path
            fill="#F9F7F8"
            d="M1014.357,64.9v957.461c0,25.351-20.549,45.899-45.899,45.899H208.93
            c-25.351,0-45.901-20.549-45.901-45.899V64.9c0-25.35,20.551-45.9,45.901-45.9h759.528C993.809,19,1014.357,39.551,1014.357,64.9
            z"
          />
        </g>
        <path
          fill="#EFEDEF"
          d="M574.473,971.206c-90.848,0-164.495-73.646-164.495-164.493V19H208.93
          c-25.351,0-45.901,20.551-45.901,45.9v957.461c0,25.351,20.551,45.899,45.901,45.899h759.528
          c25.351,0,45.899-20.549,45.899-45.899v-51.155H574.473z"
        />
        <path
          fill="#FEC165"
          d="M950.933,737.554V234.861c0-7.122-5.774-12.896-12.897-12.896H239.354c-7.12,0-12.896,5.774-12.896,12.896
          v502.692H950.933z"
        />
        <path
          fill="#FDB441"
          d="M409.978,221.965H239.354c-7.12,0-12.896,5.774-12.896,12.896v502.692h183.52V221.965z"
        />
        <circle fill="#FEE903" cx="588.693" cy="600.309" r="246.948" />
        <path
          fill="#F4D902"
          d="M409.978,770.729V429.889c-42.274,44.316-68.229,104.339-68.229,170.419
          C341.748,666.391,367.703,726.41,409.978,770.729z"
        />
        <g>
          <path
            fill="#99E6FC"
            d="M902.813,668.316c-57.591-25.393-122.604-28.267-182.203-8.034l-51.163,17.336
            c-52.369,17.759-109.135,17.759-161.505,0l-51.163-17.336c-59.602-20.232-124.611-17.358-182.182,8.034l-48.142,21.226v105.269
            l80.12,33.354h599.658l44.699-33.354V689.542L902.813,668.316z"
          />
          <path
            fill="#62DBFB"
            d="M409.978,828.165V649.264c-45.72-6.239-92.605,0.184-135.379,19.053l-48.141,21.226v105.269
            l80.119,33.354H409.978z"
          />
          <path
            fill="#62DBFB"
            d="M950.933,794.811v61.709c0,5.452-4.424,9.878-9.879,9.878H236.332c-5.453,0-9.877-4.426-9.877-9.878
            v-61.709l48.142-21.229c57.57-25.39,122.58-28.268,182.182-8.055l51.163,17.358c52.37,17.759,109.136,17.759,161.505,0
            l51.163-17.358c59.6-20.213,124.612-17.335,182.203,8.055L950.933,794.811z"
          />
          <path
            fill="#01D0FB"
            d="M236.332,866.397h184.86c-7.214-18.51-11.215-38.625-11.215-59.685v-52.188
            c-45.72-6.231-92.605,0.192-135.379,19.061l-48.141,21.226v61.71c-0.003,5.451,4.421,9.875,9.874,9.875V866.397z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const UserOctagon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0002 22.75C11.3302 22.75 10.6502 22.58 10.0502 22.23L4.11016 18.8C2.91016 18.1 2.16016 16.81 2.16016 15.42V8.57999C2.16016 7.18999 2.91016 5.89999 4.11016 5.19999L10.0502 1.77C11.2502 1.07 12.7402 1.07 13.9502 1.77L19.8902 5.19999C21.0902 5.89999 21.8402 7.18999 21.8402 8.57999V15.42C21.8402 16.81 21.0902 18.1 19.8902 18.8L13.9502 22.23C13.3502 22.58 12.6702 22.75 12.0002 22.75ZM12.0002 2.74998C11.5902 2.74998 11.1702 2.85998 10.8002 3.06998L4.86016 6.49998C4.12016 6.92998 3.66016 7.71999 3.66016 8.57999V15.42C3.66016 16.27 4.12016 17.07 4.86016 17.5L10.8002 20.93C11.5402 21.36 12.4602 21.36 13.2002 20.93L19.1402 17.5C19.8802 17.07 20.3402 16.28 20.3402 15.42V8.57999C20.3402 7.72999 19.8802 6.92998 19.1402 6.49998L13.2002 3.06998C12.8302 2.85998 12.4102 2.74998 12.0002 2.74998Z"
        fill="currentColor"
      />
      <path
        d="M11.9999 11.7501C10.2999 11.7501 8.91992 10.37 8.91992 8.67004C8.91992 6.97004 10.2999 5.59009 11.9999 5.59009C13.6999 5.59009 15.0799 6.97004 15.0799 8.67004C15.0799 10.37 13.6999 11.7501 11.9999 11.7501ZM11.9999 7.09009C11.1299 7.09009 10.4199 7.80004 10.4199 8.67004C10.4199 9.54004 11.1299 10.2501 11.9999 10.2501C12.8699 10.2501 13.5799 9.54004 13.5799 8.67004C13.5799 7.80004 12.8699 7.09009 11.9999 7.09009Z"
        fill="currentColor"
      />
      <path
        d="M16 17.4101C15.59 17.4101 15.25 17.0701 15.25 16.6601C15.25 15.2801 13.79 14.1501 12 14.1501C10.21 14.1501 8.75 15.2801 8.75 16.6601C8.75 17.0701 8.41 17.4101 8 17.4101C7.59 17.4101 7.25 17.0701 7.25 16.6601C7.25 14.4501 9.38 12.6501 12 12.6501C14.62 12.6501 16.75 14.4501 16.75 16.6601C16.75 17.0701 16.41 17.4101 16 17.4101Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const BluetoothCircle = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.45004 13.5825C9.28504 13.5825 9.15752 13.5375 9.08252 13.5075C8.91752 13.4325 8.54254 13.185 8.54254 12.5025V10.5L6.81753 12.0825C6.59253 12.2925 6.23253 12.2775 6.02253 12.045C5.81253 11.8125 5.82752 11.46 6.06002 11.25L8.52002 8.9925L6.06002 6.73502C5.82752 6.52502 5.81253 6.17252 6.02253 5.94002C6.23253 5.71502 6.59253 5.69251 6.81753 5.90251L8.54254 7.48502V5.49002C8.54254 4.81502 8.91752 4.56752 9.08252 4.48502C9.24002 4.41002 9.67504 4.28251 10.2 4.71001L11.745 6.00001C11.97 6.18751 12.105 6.46501 12.1125 6.75001C12.12 7.03501 12 7.32 11.7825 7.515L10.185 8.97751L11.7825 10.44C12 10.6425 12.12 10.92 12.1125 11.205C12.105 11.49 11.97 11.7675 11.745 11.955L10.2 13.245C9.91504 13.515 9.65254 13.5825 9.45004 13.5825ZM9.67503 10.0575V12.2475L10.9275 11.205L9.67503 10.0575ZM9.67503 5.75252V7.94251L10.9275 6.79501L9.67503 5.75252Z"
        fill="#292D32"
      />
      <path
        d="M9 17.0625C4.5525 17.0625 0.9375 13.4475 0.9375 9C0.9375 4.5525 4.5525 0.9375 9 0.9375C13.4475 0.9375 17.0625 4.5525 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625ZM9 2.0625C5.175 2.0625 2.0625 5.175 2.0625 9C2.0625 12.825 5.175 15.9375 9 15.9375C12.825 15.9375 15.9375 12.825 15.9375 9C15.9375 5.175 12.825 2.0625 9 2.0625Z"
        fill="#292D32"
      />
    </svg>
  );
};

export const FingerCircle = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17.0625C4.5525 17.0625 0.9375 13.4475 0.9375 9C0.9375 4.5525 4.5525 0.9375 9 0.9375C13.4475 0.9375 17.0625 4.5525 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625ZM9 2.0625C5.175 2.0625 2.0625 5.175 2.0625 9C2.0625 12.825 5.175 15.9375 9 15.9375C12.825 15.9375 15.9375 12.825 15.9375 9C15.9375 5.175 12.825 2.0625 9 2.0625Z"
        fill="#292D32"
      />
      <path
        d="M9.00019 11.7225C8.01019 11.7225 7.2002 10.9125 7.2002 9.92252V8.07004C7.2002 7.08004 8.01019 6.27002 9.00019 6.27002C9.99019 6.27002 10.8002 7.08004 10.8002 8.07004V9.92252C10.8002 10.92 9.99019 11.7225 9.00019 11.7225ZM9.00019 7.40253C8.63269 7.40253 8.3252 7.70255 8.3252 8.07755V9.93002C8.3252 10.305 8.62519 10.605 9.00019 10.605C9.37519 10.605 9.67519 10.305 9.67519 9.93002V8.07755C9.67519 7.70255 9.36769 7.40253 9.00019 7.40253Z"
        fill="#292D32"
      />
      <path
        d="M9 14.115C6.6225 14.115 4.6875 12.18 4.6875 9.80252V8.19751C4.6875 5.82001 6.6225 3.88501 9 3.88501C11.22 3.88501 13.065 5.55004 13.29 7.75504C13.32 8.06254 13.095 8.33999 12.7875 8.37C12.48 8.4075 12.2025 8.17501 12.1725 7.86751C12.0075 6.24001 10.6425 5.01001 9 5.01001C7.245 5.01001 5.8125 6.44251 5.8125 8.19751V9.80252C5.8125 11.5575 7.245 12.99 9 12.99C10.65 12.99 12.045 11.7 12.18 10.0575C12.2025 9.75 12.4725 9.51749 12.7875 9.53999C13.095 9.56249 13.3275 9.83254 13.305 10.1475C13.125 12.375 11.235 14.115 9 14.115Z"
        fill="#292D32"
      />
    </svg>
  );
};

export const Card = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 6.9375H1.5C1.1925 6.9375 0.9375 6.6825 0.9375 6.375C0.9375 6.0675 1.1925 5.8125 1.5 5.8125H16.5C16.8075 5.8125 17.0625 6.0675 17.0625 6.375C17.0625 6.6825 16.8075 6.9375 16.5 6.9375Z"
        fill="#292D32"
      />
      <path
        d="M6 12.9375H4.5C4.1925 12.9375 3.9375 12.6825 3.9375 12.375C3.9375 12.0675 4.1925 11.8125 4.5 11.8125H6C6.3075 11.8125 6.5625 12.0675 6.5625 12.375C6.5625 12.6825 6.3075 12.9375 6 12.9375Z"
        fill="#292D32"
      />
      <path
        d="M10.875 12.9375H7.875C7.5675 12.9375 7.3125 12.6825 7.3125 12.375C7.3125 12.0675 7.5675 11.8125 7.875 11.8125H10.875C11.1825 11.8125 11.4375 12.0675 11.4375 12.375C11.4375 12.6825 11.1825 12.9375 10.875 12.9375Z"
        fill="#292D32"
      />
      <path
        d="M13.17 15.9375H4.83C1.845 15.9375 0.9375 15.0375 0.9375 12.0825V5.9175C0.9375 2.9625 1.845 2.0625 4.83 2.0625H13.1625C16.1475 2.0625 17.055 2.9625 17.055 5.9175V12.075C17.0625 15.0375 16.155 15.9375 13.17 15.9375ZM4.83 3.1875C2.475 3.1875 2.0625 3.5925 2.0625 5.9175V12.075C2.0625 14.4 2.475 14.805 4.83 14.805H13.1625C15.5175 14.805 15.93 14.4 15.93 12.075V5.9175C15.93 3.5925 15.5175 3.1875 13.1625 3.1875H4.83Z"
        fill="#292D32"
      />
    </svg>
  );
};

export const Unlock = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 17.0625H5.25C1.9425 17.0625 0.9375 16.0575 0.9375 12.75V11.25C0.9375 7.9425 1.9425 6.9375 5.25 6.9375H12.75C16.0575 6.9375 17.0625 7.9425 17.0625 11.25V12.75C17.0625 16.0575 16.0575 17.0625 12.75 17.0625ZM5.25 8.0625C2.565 8.0625 2.0625 8.5725 2.0625 11.25V12.75C2.0625 15.4275 2.565 15.9375 5.25 15.9375H12.75C15.435 15.9375 15.9375 15.4275 15.9375 12.75V11.25C15.9375 8.5725 15.435 8.0625 12.75 8.0625H5.25Z"
        fill="#292D32"
      />
      <path
        d="M4.5 8.0625C4.1925 8.0625 3.9375 7.8075 3.9375 7.5V6C3.9375 3.825 4.4625 0.9375 9 0.9375C12.36 0.9375 14.0625 2.385 14.0625 5.25C14.0625 5.5575 13.8075 5.8125 13.5 5.8125C13.1925 5.8125 12.9375 5.5575 12.9375 5.25C12.9375 3.765 12.4875 2.0625 9 2.0625C5.73 2.0625 5.0625 3.6375 5.0625 6V7.5C5.0625 7.8075 4.8075 8.0625 4.5 8.0625Z"
        fill="#292D32"
      />
      <path
        d="M9 14.4375C7.6575 14.4375 6.5625 13.3425 6.5625 12C6.5625 10.6575 7.6575 9.5625 9 9.5625C10.3425 9.5625 11.4375 10.6575 11.4375 12C11.4375 13.3425 10.3425 14.4375 9 14.4375ZM9 10.6875C8.28 10.6875 7.6875 11.28 7.6875 12C7.6875 12.72 8.28 13.3125 9 13.3125C9.72 13.3125 10.3125 12.72 10.3125 12C10.3125 11.28 9.72 10.6875 9 10.6875Z"
        fill="#292D32"
      />
    </svg>
  );
};

export const Clock = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17.0625C4.5525 17.0625 0.9375 13.4475 0.9375 9C0.9375 4.5525 4.5525 0.9375 9 0.9375C13.4475 0.9375 17.0625 4.5525 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625ZM9 2.0625C5.175 2.0625 2.0625 5.175 2.0625 9C2.0625 12.825 5.175 15.9375 9 15.9375C12.825 15.9375 15.9375 12.825 15.9375 9C15.9375 5.175 12.825 2.0625 9 2.0625Z"
        fill="#292D32"
      />
      <path
        d="M11.7825 11.9474C11.685 11.9474 11.5875 11.9249 11.4975 11.8649L9.17248 10.4774C8.59498 10.1324 8.16748 9.37495 8.16748 8.70745V5.63245C8.16748 5.32495 8.42248 5.06995 8.72998 5.06995C9.03748 5.06995 9.29248 5.32495 9.29248 5.63245V8.70745C9.29248 8.97745 9.51748 9.37495 9.74998 9.50995L12.075 10.8974C12.345 11.0549 12.4275 11.3999 12.27 11.6699C12.1575 11.8499 11.97 11.9474 11.7825 11.9474Z"
        fill="#292D32"
      />
    </svg>
  );
};

export const Lock = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 19.25C10.98 19.25 10.04 18.79 9.41999 17.98C9.16999 17.65 9.22999 17.18 9.55999 16.93C9.88999 16.68 10.36 16.74 10.61 17.07C10.94 17.51 11.45 17.75 12 17.75C12.96 17.75 13.75 16.96 13.75 16C13.75 15.61 13.63 15.24 13.39 14.94C13.14 14.61 13.2 14.14 13.53 13.89C13.86 13.64 14.33 13.7 14.58 14.03C15.02 14.6 15.25 15.28 15.25 16C15.25 17.79 13.79 19.25 12 19.25Z"
        fill="#292D32"
      />
      <path
        d="M17 22.75H7C6.63 22.75 6.28 22.74 5.95 22.72C5.54 22.7 5.22 22.34 5.24 21.93C5.26 21.52 5.61 21.22 6.03 21.22C6.33 21.24 6.65 21.24 6.99 21.24H16.99C20.56 21.24 21.24 20.56 21.24 16.99V14.99C21.24 11.48 20.5 10.91 17.95 10.76C17.65 10.74 17.33 10.74 16.99 10.74H7C3.43 10.74 2.75 11.42 2.75 14.99V16.99C2.75 18.73 2.95 19.76 3.41 20.32C3.67 20.64 3.62 21.12 3.29 21.37C2.97 21.63 2.5 21.58 2.24 21.25C1.54 20.4 1.25 19.16 1.25 17V15C1.25 10.59 2.59 9.25 7 9.25H17C17.37 9.25 17.72 9.26 18.04 9.28C22 9.5 22.75 11.46 22.75 15V17C22.75 21.41 21.41 22.75 17 22.75Z"
        fill="#292D32"
      />
      <path
        d="M6 10.75C5.59 10.75 5.25 10.41 5.25 10V8C5.25 5.1 5.95 1.25 12 1.25C16.07 1.25 18.18 2.58 18.64 5.44C18.71 5.85 18.43 6.23 18.02 6.3C17.61 6.37 17.23 6.09 17.16 5.68C16.91 4.16 16.12 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.75 10.41 6.41 10.75 6 10.75Z"
        fill="#292D32"
      />
      <path
        d="M2 22.7499C1.81 22.7499 1.62 22.6799 1.47 22.5299C1.18 22.2399 1.18 21.7599 1.47 21.4699L21.47 1.46994C21.76 1.17994 22.24 1.17994 22.53 1.46994C22.82 1.75994 22.82 2.23994 22.53 2.52994L2.53 22.5299C2.38 22.6799 2.19 22.7499 2 22.7499Z"
        fill="#292D32"
      />
    </svg>
  );
};
