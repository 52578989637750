import {
  LIST_USER_MANAGEMENT_SUCCESS,
  CREATE_USER_MANAGEMENT_SUCCESS,
  GET_USER_MANAGEMENT_SUCCESS,
  UPDATE_USER_MANAGEMENT_SUCCESS,
  UPDATE_USER_MANAGEMENT_PASSWORD_SUCCESS,
  DELETE_USER_MANAGEMENT_SUCCESS,
} from "../constants/UserManagement";
  
  export const listUserManagementSuccess = (payload) => {
    return {
      type: LIST_USER_MANAGEMENT_SUCCESS,
      payload,
    };
  };
  
  export const createUserManagementSuccess = (payload) => {
    return {
      type: CREATE_USER_MANAGEMENT_SUCCESS,
      payload,
    };
  };
  
  export const getUserManagementSuccess = (payload) => {
    return {
      type: GET_USER_MANAGEMENT_SUCCESS,
      payload,
    };
  };
  
  export const updateUserManagementSuccess = (payload) => {
    return {
      type: UPDATE_USER_MANAGEMENT_SUCCESS,
      payload,
    };
  };
  
  export const updateUserManagementPasswordSuccess = (payload) => {
    return {
      type: UPDATE_USER_MANAGEMENT_PASSWORD_SUCCESS,
      payload,
    };
  };
  
  export const deleteUserManagementSuccess = (payload) => {
    return {
      type: DELETE_USER_MANAGEMENT_SUCCESS,
      payload,
    };
  };