import antdThTH from 'antd/es/locale/th_TH';
import enMsg from "../locales/en_US.json";

const ThLang = {
  antd: antdThTH,
  locale: 'th-TH',
  messages: {
    ...enMsg
  },
};
export default ThLang;
